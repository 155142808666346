<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Using the data from
        <a
          href="https://ecampusontario.pressbooks.pub/genchemforgeegees/chapter/appendix-h-ionization-constants-of-weak-acids/"
          rel="noopener noreferrer"
          target="_blank"
          >Appendix H</a
        >
        of the textbook
        <a
          href="https://ecampusontario.pressbooks.pub/genchemforgeegees/"
          rel="noopener noreferrer"
          target="_blank"
          >Gen Chem for Gee Gees</a
        >, calculate equilibrium concentrations for each of the following species for a
        <number-value :value="concentration" unit="\text{mM}" /> solution of
        <chemical-latex content="Na2CO3\text{.}" /> Please express your answers to 3 significant
        figures despite the <stemble-latex content="$\text{K}_\text{a}$" /> /
        <stemble-latex content="$\text{K}_\text{b}$" /> values only having 2 significant figures.
      </p>

      <calculation-input
        v-model="inputs.naConc"
        class="mb-3"
        prepend-text="$\ce{a) [Na+]}\hspace{0.45cm}:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.bh2Conc"
        class="mb-3"
        prepend-text="$\ce{b) [H2CO3]}\hspace{0.06cm}:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.bhConc"
        class="mb-3"
        prepend-text="$\ce{c) [HCO3^-]}:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.bConc"
        class="mb-3"
        prepend-text="$\ce{d) [CO3^2-]}\hspace{0.06cm}:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.h3oConc"
        class="mb-3"
        prepend-text="$\ce{e) [H3O^+]}\hspace{0.19cm}:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question470b',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        naConc: null,
        bConc: null,
        bhConc: null,
        bh2Conc: null,
        h3oConc: null,
      },
    };
  },
  computed: {
    concentration() {
      return this.taskState.getValueBySymbol('concentration').content;
    },
  },
};
</script>
